@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500');

body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

div > .MuiChip-deleteIcon {
  display: none;
}

.highlighted {
  background: lightslategray !important;
}
